import './style.css';
import 'flowbite/dist/flowbite.js';
import './sidebar';
import './charts';
import './dark-mode';

// Have the courage to follow your heart and intuition.
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/sw.js')
        .then((registration) => {
          console.log('ServiceWorker registrado con éxito:', registration);
        })
        .catch((error) => {
          console.error('Error al registrar el ServiceWorker:', error);
        });
    });
  }
  